const theme = {
  colors: {
    one: '#22577a',
    two: '#38a3a5',
    three: '#57cc99',
    four: '#80ed99',
    five: '#c7f9cc',
    six: '#e5e5e5',
    seven: '#446061',
  },

  fontSize: {
    paragraph: '1.3em',
  },
}

export default theme
